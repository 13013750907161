<template>
  <div
    :style="`
    --color: var(--${styles[theme].color});
    --bg: var(--${styles[theme].bg});
    --bg-hover: var(--${styles[theme].bgHover})`"
    class="cta-button-with-options"
    v-class-mod:cta-button-with-options="[
      `size-${size}`,
      { isLoading: internalIsLoading || isLoading, isDisabled: disabled },
    ]"
  >
    <span
      :tabindex="disabled ? -1 : 0"
      class="cta-button-with-options__title"
      @click="(e) => callAction(() => action(e))"
      @keydown.enter="(e) => callAction(() => action(e))"
    >
      {{ title }}
    </span>
    <more-options
      :cta-options="
        options.map((o) => {
          if (o === 'seperator' || !('action' in o)) {
            return o;
          }
          return {
            ...o,
            action: (e) => callAction(() => o.action(e)),
          };
        })
      "
      :icon="{
        icon: 'arrow_drop_down',
        buttonSize: 'medium',
        iconColor: styles[theme].color,
        iconHoverColor: styles[theme].color,
        background: 'black-10',
      }"
      class="cta-button-with-options__options"
    />
    <loader-bars v-if="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { addErrorMessage } from "@horizon56/bootstrap";
import { ButtonSize, ColorName } from "@horizon56/styles/types";

import LoaderBars from "../loaders/loader-bars.vue";
import { type Option } from "./cta-options.vue";
import MoreOptions from "./more-options.vue";

type Theme = "green" | "gray";
type Style = { bg: ColorName; bgHover: ColorName; color: ColorName };

export type Props = {
  title: string;
  options: Option[];
  action: (event: MouseEvent | KeyboardEvent) => void;
  size?: ButtonSize;
  isLoading?: boolean;
  disabled?: boolean;
  theme?: Theme;
  buttonTooltip?: string;
};

withDefaults(defineProps<Props>(), {
  size: "medium",
  theme: "gray",
});

const styles: { [key in Theme]: Style } = {
  gray: { bg: "black-10", bgHover: "black-20", color: "black-90" },
  green: { bg: "green-500", bgHover: "green-600", color: "static-white-90" },
};

const internalIsLoading = ref(false);

const callAction = async (fn: () => void) => {
  internalIsLoading.value = true;
  try {
    await fn();
  } catch (e) {
    addErrorMessage(e);
  }
  internalIsLoading.value = false;
};
</script>

<style lang="scss" scoped>
.cta-button-with-options {
  align-items: center;
  background: var(--bg);
  border-radius: var(--app-radius-large);
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  &--isLoading #{&}__options {
    pointer-events: none;
  }
  &--isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
  @each $size in $buttonSizes {
    &--size-#{$size} {
      height: var(--app-button-height-#{$size});
    }
  }
  &--size-large {
    padding-right: 4px;
  }
  &:has(#{&}__title:hover) {
    background: var(--bg-hover);
  }
  &__title {
    display: flex;
    white-space: nowrap;
    padding: 0 8px 0 16px;
    cursor: pointer;
    user-select: none;
    height: 100%;
    align-items: center;
    outline: none;
    color: var(--color);
  }
  &:has(&__title:focus-visible) {
    @include focus-outline-inner;
  }
}
</style>
